<template>
  <div class="pricing">
    <h1>Pricing</h1>
    <p>
      All our online transactions are powered by Stripe™ and we don't store any
      of your credit card information. Moreover, if you opt to use our
      subscription plan, then you can easily cancel it at any time. Every course
      you purchase or subscribe to will automatically be assocated with your
      account.
    </p>
    <h2>Thai phrases</h2>
    <p>
      The subscription will give you access to all our current and future phrases as long as your subscription is active.
    </p>
    <div class="all">
      <div class="card">
        <h3>Unlock all courses</h3>
        <p>Unlock all our current and future courses for ฿500/month (Thai bath).</p>
        <button>Coming soon</button>
        <!-- <button v-if="authUser" @click="subscribeAllCourses('price_1J7wmjHofled9GKuFLcRyXwT')">Subscribe</button>
        <button v-else @click="login()">Login to subscribe</button> -->
      </div>
    </div>
    <p>
      Paying customers can request new phrases and courses to be added to our already-large collection.
    </p>
    <h2>Translation Services</h2>
    <p>
        We charge ฿100/100 words. You can copy-paste the text you wish to translate into the text-area below to calculate how much it would cost.
    </p>
    <textarea v-model="translationText" rows="4"></textarea>
    <p v-if="isThaiText" class="warning">
      The word/price counter does not support Thai text at the moment.
      However, we do offer Thai to English translation services. Please reach
      out to us at translation@pocketlang.com for more information.
    </p>
    <p v-else>Price: ฿{{ price }} <br />Word count: {{ wordCount }}</p>
  </div>
</template>

<script>
export default {
  name: 'pricing',
  data() {
    return {
      translationText: '',
    }
  },
  mounted() {
    document.title = "Pricing"
  },
  computed: {
    wordCount() {
      if (this.translationText.length === 0) {
        return 0;
      }
      if (this.translationText.match('[\u0E00-\u0E7F]+')) {
        return 0;
      }
      return (this.translationText.match(/\S+/g) || []).length;
    },
    price() {
      return Math.ceil(this.wordCount / 100) * 100;
    },
    isThaiText() {
      if (this.translationText.match('[\u0E00-\u0E7F]+')) {
        return true;
      }
      return false;
    },
  },
}
</script>

<style scoped lang="scss">
.pricing {
  margin: 0 .5rem;
  .card {
    padding: 1.5rem;
    background-color: #d8eefe;
    border-radius: 0.5rem;
    display: grid;
    grid-template-rows: auto 1fr auto;
    button {
      height: 2.5rem;
      padding: 0 1rem;
    }
  }
  button {
    background-color: #ef4565;
    color: #fffffe;
    padding: 0;
    border: 0;
    font-weight: 700;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    cursor: pointer;
    border-radius: .2rem;
  }
}
</style>